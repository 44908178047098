<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="col-lg-12 col-xl-12 text-left" [ngClass]="{'bgBlue': !newVendor, 'bgMainSection': !newVendor}"  *ngIf="!openConfirmationPage">
    <form [formGroup]="paymentForm">
        <div class="container" formGroupName="paymentDetails" *ngIf="!processPayment">
            <div class="row" *ngIf="!newVendor">
                <div class="col-12 text-left mt-4">
                    <label class="mainHeading">Make Payment</label>
                </div>
            </div>
            <div class="bgWghite pt-2 mt-2 pl-2 pr-2"  [ngClass]="{'shadow': !newVendor}">
                <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left">
                        <div class="row mt-0" *ngIf="!newVendor">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label for="txtFirstName" i18n>Prior Balance</label>
                                <input type="text" class="form-control text-right" readonly formControlName="priorBalance"
                                    (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Rental Charges</label>
                                <input type="text" class="form-control text-right" readonly
                                formControlName="rentalCharge" (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Other Charges</label>
                                <input type="text" class="form-control text-right" readonly
                                formControlName="otherCharge" (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                              <label i18n>Discounts</label>
                              <input type="text" class="form-control text-right" readonly formControlName="discounts"
                              (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Sales Tax</label>
                                <input type="text" class="form-control text-right" readonly
                                formControlName="salesTax" (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>{{secondTaxName}}</label>
                                <input type="text" class="form-control text-right" readonly
                                formControlName="secondTax" (keypress)="numberOnly($event)">
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Additional Charges</label>
                                <input type="text" class="form-control text-right" readonly formControlName="additionalCharges"
                                    (keypress)="numberOnly($event)">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 text-left">
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Total Charge</label>
                                <input type="text" class="form-control text-right" formControlName="totalDue" readonly>
                            </div>
                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label i18n>Payment Amount</label>
                                <input type="text" class="form-control text-right" formControlName="paymentAmount"
                                    (keypress)="numberOnly($event)" 
                                    (input)="setCurrencyFormat(paymentForm.controls.paymentDetails.get('paymentAmount'))"
                                    (blur)="setMinimum(paymentForm.controls.paymentDetails.get('paymentAmount'))"
                                    (click)="handleClick($event)" (dblclick)="handledblClick($event)"
                                    (change)="setMinimum(paymentForm.controls.paymentDetails.get('paymentAmount'))">
                            </div>

                        </div>
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                            <label i18n>Convenience Fee</label>
                            <input type="text" class="form-control text-right" formControlName="convenienceFee"
                                    readonly>
                            </div>
                        </div>

                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label style="font-weight: 600; font-size: 16px;" i18n>Total Payment</label>
                                <input type="text" class="form-control text-right" readonly formControlName="totalPayment">
                            </div>
                        </div>

                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left mt-2">
                                <label style="font-weight: 600; font-size: 16px;" i18n>Balance Due</label>
                                <input type="text" class="form-control text-right" readonly formControlName="balanceDue"
                                (keypress)="numberOnly($event)">
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let rule of rulesFormArray; let i = index">
                    <div class="row">
                        <div class="col-12 text-left">
                            <div>
                                <label class="mb-0">
                                    <input type="checkbox" class="CheckboxClass mt-3" [formControlName]="isChecked" (change)="updateArrayDirectly(i,$event)">
                                    <label class="chkAlign mb-0">{{rule.rulesAndRegualtions}}</label>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="rule.AgreementLinkRequired">
                        <div class="col-12 text-left mt-1">
                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4xMjQiIGhlaWdodD0iMTUuODQzIiB2aWV3Qm94PSIwIDAgMjEuMTI0IDE1Ljg0MyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkPSJNMjEuMTI0LDEwLjFBMS44NiwxLjg2LDAsMCwxLDE5LjI3NiwxMS45aC00LjEyYTIuMDI5LDIuMDI5LDAsMCwxLS4yNiwyLjkyNywxLjg5LDEuODksMCwwLDEtLjY3OSwyLjU3MWMuMzcsMi4wMjctLjkwNSwyLjk0LTMsMi45NC0uMTEzLDAtLjU0OC0uMDA4LS42Ni0uMDA4LTIuNTU3LjAwNy0zLjE3Mi0xLjI4Mi01LjEtMS41ODFhLjk4OS45ODksMCwwLDEtLjgzNi0uOTc4VjEwLjdoMEExLjk4LDEuOTgsMCwwLDEsNS44LDguODljMS4xOTItLjUzNiwzLjkzNy0yLjAyMyw0LjQzNy0zLjE5YTEuNzU5LDEuNzU5LDAsMCwxLDEuNjUtMS4yQTEuOTgyLDEuOTgyLDAsMCwxLDEzLjcsNy4yNmE1LjYwOCw1LjYwOCwwLDAsMS0uNTg0LDEuMDI4aDYuMTU3QTEuODksMS44OSwwLDAsMSwyMS4xMjQsMTAuMVptLTE3LjE2My4wMTR2Ny45MjJhLjk5Ljk5LDAsMCwxLS45OS45OUguOTlhLjk5Ljk5LDAsMCwxLS45OS0uOTlWMTAuMTExYS45OS45OSwwLDAsMSwuOTktLjk5aDEuOThBLjk5Ljk5LDAsMCwxLDMuOTYxLDEwLjExMVpNMi44MDYsMTcuMDQyYS44MjUuODI1LDAsMSwwLS44MjUuODI1QS44MjUuODI1LDAsMCwwLDIuODA2LDE3LjA0MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNSkiIGZpbGw9IiMwNzI4ZmYiLz4KPC9zdmc+Cg=="
                                class="img-fluid" />
                            &nbsp;<a [href]="rule.AgreementBlobURL" target="_blank" class="rules">{{rule.AgreementLinkTextContent}}</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mb-3" style="margin-top: 12px;">
                        <button type="button" i18n class="btn btn-outline-primary mr-1" (click)="cancel()">Cancel</button>
                        <button type="button" i18n class="btn btn-primary" (click)="saveRentalWithPayment()" [disabled]="!allCheckboxesCheckedValidator()">Submit</button>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div *ngIf="processPayment">
            <app-payment [VendorId]="vendorId" [keyedInTransaction]="keyedInTransaction" [MarketId]="marketId"
                [CCAmount]="paymentAmount" [TransactionType]="transactionType" [voidPayment]="voidPayment"
                (PaymentResponse)="PaymentResponse($event)" [VerifoneId]="verifoneId"
                (ClosePaymentPage)="ClosePaymentPage($event)"></app-payment>
        </div>

        <div *ngIf="voidPayment">
            <app-payment [VendorId]="vendorId" [voidPayment]="voidPayment" (PaymentResponse)=voidPaymentResponse()
                [VerifoneId]="verifoneId"></app-payment>
        </div>      
    </form>
</div>
<div *ngIf="openConfirmationPage">
    <app-rental-confirmation [VendorId]="vendorId" [AccountReceipId]="accountReceipId" [RentalType]="this.paymentData.RentalType"></app-rental-confirmation>
</div>