import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RentalService } from 'src/app/services/Rental.service';
import { VendorService} from 'src/app/services/Vendor.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../SharedData.service';
import { ToastrService } from 'ngx-toastr';
import { customCurrencyPipe } from '../customCurrency.pipe';
import { Location } from '@angular/common';
import { PaymentService } from 'src/app/services/paymentService.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import printJS from 'print-js';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { UserService } from 'src/app/services/User.service';
import { GeneralService } from 'src/app/services/General.service';

@Component({
  selector: 'app-vendor-payments',
  templateUrl: './vendor-payments.component.html',
  styleUrls: ['./vendor-payments.component.scss']
})
export class VendorPaymentsComponent implements OnInit {
  vendorId;
  vendorInfo;
  vendorMarketID;
  displayContract=true;
  rules: any[] = [];
  totalRentalCharges = 0;
  vendorName;
  paymentData;
  public loaderIcon: Promise<boolean>;
  private subscriptions: Subscription[] = [];
  marketId
  paymentForm: FormGroup;
  warnExcessPayment;
  warnZeroPayment;
  displayOtherChargeByBooth;
  calculateChangeAmount;
  keyedInTransaction = true;
  processPayment = false;
  voidPayment = false;
  verifoneId = "";
  transactionType="CREDITSALE"
  creditCardAmount;
  DisablePayment = false;
  displayAmountPaid=false;
  paymentGatewayCredentials;
  processCreditCardOnline;
  applyCCCharge = false;
  thermalPrinting = false;
  actualTotalDue = 0;
  disablePayBtnAfterSubmit=false;
  CcfeeinPercentage;
  AllowPartialPay;
  paymentAmount=0;
  accountReceipId;
  secondTaxName = "Sec Level Tax";
  private clickTimeout: any;
  @Input() newVendor;
  @Input() businessLicenseInfo;
  @Output() cancelClick = new EventEmitter<any>();
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  paymentOnly = false; 
  openConfirmationPage = false; 

  constructor(private operationRentalService: RentalService,
    private vendorService: VendorService,
    private rentalService: RentalService,
    private marketInfoService: MarketInfoService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private userService:UserService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private customCurrency:customCurrencyPipe,
    private paymentService: PaymentService,
    private generalService:GeneralService,
    private location: Location,
    private renderer: Renderer2) {
    this.paymentForm = this.formBuilder.group({
      paymentDetails: this.formBuilder.group({
        priorBalance: this.customCurrency.transform('0.00'),
        rentalCharge: this.customCurrency.transform('0.00'),
        otherCharge: this.customCurrency.transform('0.00'),
        discounts: this.customCurrency.transform('0.00'),
        additionalCharges: this.customCurrency.transform('0.00'),
        paymentAmount: [{ value: this.customCurrency.transform('0.00'), disabled: true }],      
        convenienceFee: this.customCurrency.transform('0.00'),
        changeAmount: this.customCurrency.transform('0.00'),
        balanceDue: this.customCurrency.transform('0.00'),
        totalPayment: this.customCurrency.transform('0.00'),
        totalDue:this.customCurrency.transform('0.00'),
        salesTax:this.customCurrency.transform('0.00'),
        secondTax:this.customCurrency.transform('0.00'),
        rules: this.formBuilder.array(this.initializeRulesArray())
      })
    });
  }

  initializeRulesArray(): FormGroup[] {
    return this.rules.map(rule => this.formBuilder.group({
      isChecked: [false],  // Initialize checkbox with false
      rulesAndRegualtions: [rule.rulesAndRegualtions],
      AgreementLinkRequired: [rule.AgreementLinkRequired],
      AgreementBlobURL: [rule.AgreementBlobURL],
      AgreementLinkTextContent: [rule.AgreementLinkTextContent]
    }));
  }

  updateArrayDirectly(index: number, updatedValue: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const control = this.rulesFormArray.at(index);
    if (control) {
      this.paymentForm.controls['paymentDetails'].value['rules'][index].isChecked = isChecked;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.marketInfoService.setMarketId(this.marketId);
    });  

    if (!this.sharedDataService.paymentSharedData) {
      const url = 'home/' + this.marketId;
      this.router.navigate([url]);
    }

    this.paymentData = this.sharedDataService.paymentSharedData;
    this.vendorId = this.paymentData.VendorId;
    this.totalRentalCharges = (this.paymentData.PriorBalance + this.paymentData.RentalCharge + this.paymentData.SalesTax
                              + this.paymentData.SecLevelTax + this.paymentData.OtherCharge + this.paymentData.BookingFee) -this.paymentData.DiscountAmount;
    this.paymentOnly = this.paymentData.PaymentOnly;
    this.sharedDataService.rentalType = this.paymentData.RentalType;

    this.actualTotalDue = this.totalRentalCharges;
    this.paymentForm.controls['paymentDetails'].patchValue({
      priorBalance: this.customCurrency.transform(this.paymentData.PriorBalance),
      rentalCharge: this.customCurrency.transform(this.paymentData.RentalCharge),
      otherCharge: this.customCurrency.transform(this.paymentData.OtherCharge),
      discounts: this.customCurrency.transform(this.paymentData.DiscountAmount),
      additionalCharges: this.customCurrency.transform(this.paymentData.BookingFee),
      totalDue: this.customCurrency.transform(this.totalRentalCharges.toString()),
      salesTax:this.customCurrency.transform(this.paymentData.SalesTax),
      secondTax: this.customCurrency.transform(this.paymentData.SecLevelTax),
    });
    this.getVendorInfobyId();
    this.getCCCredentials();   
    this.getWebRentalSetting();
    this.getRentalPaymentSettings();
    this.getMarketRulesAndRegulations();
  }

  getMarketRulesAndRegulations() {
    const sub = this.generalService.getWebrentalMarketRules(this.marketId).subscribe((rules: any) => {
      if (rules) {
        const rulesArray = this.paymentForm.controls['paymentDetails'].get('rules') as FormArray;
        rules.forEach(rule => {
          rulesArray.push(this.formBuilder.group({
            id: rule.ID,
            isChecked: false,
            rulesAndRegualtions: rule.Rules,
            AgreementLinkRequired : rule.AgreementLinkRequired ?? false,
            AgreementLinkTextContent: rule.AgreementLinkTextContent ?? "",
            AgreementBlobURL : rule.AgreementBlobURL ?? ""
          }));
        });
      }
    });
  }

  allCheckboxesCheckedValidator(control: AbstractControl) {
    var allChecked = this.paymentForm.controls['paymentDetails'].value['rules'].filter(e=>!e.isChecked).length > 0 ? false : true;
    return allChecked;
  }

  get rulesFormArray() {
    return this.paymentForm.controls['paymentDetails'].value['rules'] as FormArray;
  }

  getWebRentalSetting() {
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.applyCCCharge = res.ApplyCcporcessingFeePercentage;
        this.CcfeeinPercentage = res.CcfeeinPercentage;
        this.AllowPartialPay = res.AllowPartialPay;             
        this.paymentForm.controls['paymentDetails'].get('paymentAmount').enable();
        if (!this.AllowPartialPay) {
          this.paymentForm.controls['paymentDetails'].get('paymentAmount').disable();
          this.paymentForm.controls['paymentDetails'].patchValue({
            paymentAmount: this.customCurrency.transform(this.actualTotalDue.toString()),
            totalPayment: this.customCurrency.transform(this.actualTotalDue.toString()),
          })
        }
        this.paymentAmount =  this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));
        const creditCardAmount = this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
        if (this.applyCCCharge && creditCardAmount > 0) {
          this.calculateConvenienceFee();
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getRentalPaymentSettings() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.getRentalSettings(this.marketId).subscribe((res: any) => {
      if (res) {       
        this.secondTaxName = res.SecondTaxName ? res.SecondTaxName: "Sec Level Tax";      
      }
      this.loaderIcon = Promise.resolve(false);
    },err=>{
      this.loaderIcon = Promise.resolve(false);
    });
  }

  setCurrencyFormat(controls) {
    const value = controls.value;
    let inputValue = value;
    if(inputValue.toString().substring(1,2) == "$")
    inputValue = `${inputValue.slice(1)}`;
    controls.setValue(inputValue);
  }

  onCheckNumKeyPress(event: KeyboardEvent) {
    const allowedChars = /[a-zA-Z0-9]/;
    const key = event.key;
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '#') {
      return;
    }
    if (!key.match(allowedChars)) {
      event.preventDefault();
    }
  }

  setMinimum(controls) {
    const value = controls.value;
    var enteredAmount = value.toString().includes('(') && value.toString().includes(')')  ? -1 * parseFloat(value.toString().replace(/[^0-9.-]/g, '')): parseFloat(value.toString().replace(/[^0-9.-]/g, ''));
    if (!enteredAmount) {
      controls.setValue(this.customCurrency.transform("0.00"));
    }
    var totalDue = this.paymentForm.controls['paymentDetails'].value["totalDue"];
    totalDue = totalDue.toString().includes('(') && totalDue.toString().includes(')')  ? -1 * parseFloat(totalDue.toString().replace(/[^0-9.-]/g, '')): parseFloat(totalDue.toString().replace(/[^0-9.-]/g, ''));
    if (enteredAmount > this.actualTotalDue) {
      this.paymentForm.controls['paymentDetails'].get("paymentAmount").setValue(this.actualTotalDue);
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Payment amount cannot be greater than total due.`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
    this.calculateBalance();
  }

  calculateBalance() {  
    const creditCardAmount = this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    this.creditCardAmount = this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const totalDue = this.actualTotalDue;
    const convenienceFee = 0;

    var totalAmount;
    totalAmount = creditCardAmount + convenienceFee;
    const balanceAmount = totalDue - totalAmount;
    
    this.paymentForm.controls['paymentDetails'].patchValue({
      totalDue: this.customCurrency.transform(totalDue.toString()),
      balanceDue: this.customCurrency.transform(balanceAmount.toString()),
      convenienceFee: this.customCurrency.transform(convenienceFee.toString()),
      totalPayment: this.customCurrency.transform(totalAmount.toString())
    })

    this.paymentAmount =  this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));

    if(this.applyCCCharge && creditCardAmount > 0){
      this.calculateConvenienceFee();
    }

    this.paymentForm.controls['paymentDetails'].get('paymentAmount').setValue(this.customCurrency.transform(creditCardAmount.toString()));
  }

  getVendorInfobyId() {
    this.vendorService.GetVendorById(this.vendorId, this.marketId).subscribe((response) => {
      if (response) {
        this.vendorInfo = response.body;
        this.vendorMarketID = this.vendorInfo.MarketVendorId;
        this.vendorName = this.vendorInfo.IsPublicVendor ? (this.paymentData.VendorName != undefined ? this.paymentData.VendorName.toString() : this.vendorInfo.VendorName) : this.vendorInfo.VendorName;
        this.marketId = this.vendorInfo.MarketId
      }
    }, (err) => {
    });
  }

  calculateConvenienceFee() {
    var convenienceFee = 0;
    var creditCardAmount = this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var totalDue = this.actualTotalDue;
    if (creditCardAmount > 0) {
      var convenienceFee = creditCardAmount * ((this.CcfeeinPercentage) / 100);
      totalDue += convenienceFee;
      this.creditCardAmount += convenienceFee;
    }
    var totalAmount = 0;
    totalAmount = creditCardAmount + convenienceFee;
    const balanceAmount = totalDue - totalAmount;

    this.paymentForm.controls['paymentDetails'].patchValue({
      convenienceFee: this.customCurrency.transform(convenienceFee.toString()),
      totalDue: this.customCurrency.transform(totalDue.toString()),
      balanceDue: this.customCurrency.transform(balanceAmount.toString()),
      totalPayment: this.customCurrency.transform(totalAmount.toString())
    })
    this.paymentAmount =  this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));
  }

  numberOnly(event): boolean {
    const inputValue = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    // Check if the input value is "$0.00"
    if (charCode == 45) {
      return false;
    }
    if (currentSelectionLength === inputValue.length) {
      return true;
    }
    if (inputValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return true; // Allow further input
    }
    // Check if the "$" sign is present in the current value
    const hasDollarSign = inputValue.includes('$');
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = inputValue.slice(0, currentCursorPosition).includes('$');
    // If the cursor is not after the "$" sign and "$" sign is present, prevent input
    if (!hasDollarSignBeforeCaret && hasDollarSign && charCode !== 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // If the entered character is a dot and there's already a dot in the input, prevent input
    if (charCode == 46 && inputValue.includes('.') && inputValue.indexOf('.') !== currentCursorPosition) {
      return false;
    }
    if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode === 47)) {
      return false;
    }
    return true; // Allow input
  }

  numberOnlyForCash(event: any) {
    // Get the current value of the input field
    const currentValue: string = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    if (currentSelectionLength === currentValue.length) {
      return true;
    }
    // Check if the input value is "0" and if it contains a "$" sign
    if (currentValue === '0' && !currentValue.includes('$')) {
      // Set the new value with "$" sign
      event.target.value = '$0';
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return;
    }
    // Check if the "$" sign is present in the current value
    const hasDollarSign = currentValue.includes('$');
    // Allow input if the "$" sign is not present
    if (!hasDollarSign) {
      return;
    }
    // Get the cursor position
    const caretPosition = event.target.selectionStart;
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = currentValue.slice(0, caretPosition).includes('$');
    // If the cursor is not after the "$" sign, prevent input
    if (!hasDollarSignBeforeCaret) {
      event.preventDefault();
      return;
    }

    // Allow digits, a dot, and an optional '-' at the beginning
    const pattern = /^-?\d*\.?\d*$/;
    // Get the character entered by the user
    const inputChar = String.fromCharCode(event.charCode);
    // Check if the input matches the pattern
    if (!pattern.test(inputChar) || // If the entered character is not allowed
      (inputChar === '.' && currentValue.includes('.')) || // Or if the dot is already present
      (inputChar === '-' && currentValue.indexOf('-') !== -1)) { // Or if '-' is entered more than once
      event.preventDefault(); // Prevent the character from being entered
    }
  }

  handledblClick(event: any) {
    clearTimeout(this.clickTimeout);

    const inputElement = event.target as HTMLInputElement;
    inputElement.select(); // Select the entire text on double-click
  }

  handleClick(event: any) {
    // Get the current value of the input field
    let currentValue: string = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    clearTimeout(this.clickTimeout);

    this.clickTimeout = setTimeout(() => {
      if (inputValue === '$0.00') {
        const selectionLength = inputElement.selectionEnd - inputElement.selectionStart;

        // If the entire text is not selected, set the cursor position after the "$" sign
        if (selectionLength !== inputValue.length) {
          inputElement.setSelectionRange(1, 1);
        }
      }
    }, 200); // Delay to check for double click
  
    if (currentSelectionLength === currentValue.length) {
      return true;
    }
    // Check if the input value is "$0.00"
    if (currentValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return;
    }

    // Check if the "$" sign is present in the current value
    const hasDollarSign = currentValue.includes('$');
    // Get the cursor position
    const caretPosition = event.target.selectionStart;
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = currentValue.slice(0, caretPosition).includes('$');
    // If the cursor is not after the "$" sign, adjust the cursor position
    if (!hasDollarSignBeforeCaret && hasDollarSign) {
      // Find the index of "$" sign
      const dollarSignIndex = currentValue.indexOf('$');
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(dollarSignIndex + 1, dollarSignIndex + 1);
    }
  }

  saveRentalWithPayment() {    
    var creditCardAmount = this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
     var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
    const totalAmountPaid = creditCardAmount  + convenienceFee;
    if (totalAmountPaid != 0) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Are you sure you want to proceed with the payment?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.value) {
          this.disablePayBtnAfterSubmit = true;
          if (this.paymentGatewayCredentials && this.processCreditCardOnline)
            this.processPayment = true;
          else {
            Swal.fire({
              title: $localize`Warning`,
              text: $localize`Credit card cannot be processed.`,
              icon: "warning",
              confirmButtonText: $localize`Ok`,
            });
          }
        }
      });
    }
    else {
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Please enter the Payment Amount!`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
  }

  PaymentResponse(response) {
    this.loaderIcon = Promise.resolve(true);
    if (response.PaymentSuccess) {
      this.verifoneId = response.VerifoneId;
      if (this.newVendor) {
        this.subscriptions.push(
          this.vendorService.SaveVendor(this.sharedDataService.newVendorContactInfo).subscribe((SaveVenresponse: any) => {
            if (SaveVenresponse.Success) {
              this.vendorId = SaveVenresponse.Id;
              this.loaderIcon = Promise.resolve(true);
              this.sharedDataService.newVendorLoginDetails.VendorId = SaveVenresponse.Id;
              this.vendorService.SaveVendorLoginDetail(this.sharedDataService.newVendorLoginDetails).subscribe((res: any) => {
                if (res.Success) {
                  const credentials =
                  {
                    username: this.sharedDataService.newVendorLoginDetails.OnlineUserId,
                    password: this.sharedDataService.newVendorLoginDetails.OnlinePassword,
                    EmailLogin: false,
                    MarketId: this.marketId
                  }
                  this.userService.getToken(credentials).subscribe(async (resTokenGen) => {
                    sessionStorage.setItem('token', resTokenGen.token);
                    if (this.businessLicenseInfo) {
                      if (this.businessLicenseInfo.BusinessLicenseNoRequired) {
                        const obj = {
                          VendorID: this.vendorId,
                          LicenseNameId: 1,
                          LicenseName: "",
                          ExpiryDate: new Date(this.businessLicenseInfo.ExpiryDate),
                          LicenseNumber: this.businessLicenseInfo.LicenseNumber
                        }
                        this.subscriptions.push(this.rentalService.updateVendorBusinessLicense(this.marketId, obj).subscribe((res: any) => {
                        }));
                      }
                    }
                    // Proceed to save booking details after both vendor and login details are saved
                    this.saveBookingDetails(response);
                    this.sharedDataService.newVendorLoginDetails ="";
                    this.sharedDataService.newVendorContactInfo = "";
                  });
                } else {
                  this.toastr.error($localize`Failed to save vendor login details`, $localize`Error`, this.toasterConfig);
                  this.loaderIcon = Promise.resolve(false);
                }
              });
            } else {
              this.toastr.error($localize`Failed to save vendor`, $localize`Error`, this.toasterConfig);
              this.loaderIcon = Promise.resolve(false);
            }
          })
        );
      } else {
        // Directly save booking details without vendor saving
        this.saveBookingDetails(response);
      }
    }
    else {
      this.processPayment = false;
      this.voidPayment = false;
      this.disablePayBtnAfterSubmit = false;
      this.loaderIcon = Promise.resolve(false);
    }
  }

  saveBookingDetails(response) {
    switch (this.paymentData.RentalType) {
      case 1:
        var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0 && this.paymentOnly) {
          this.paymentOnly = false;
        }
        var obj = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          PriorBalance: this.paymentData.PriorBalance,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: 0,
          CheckAmount: 0,
          CheckNo: "",
          ChangeAmount: 0,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount: 0,
          CardType: "",
          PaymentDone: true,
          WebRentalRequest: true,
          PaymentDueDate: this.paymentData.PaymentDueDate,
          VerifoneId: response.VerifoneId,
          DiscountAmount: this.paymentData.DiscountAmount,
          ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
          BookingFee: this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')),
          DailyRentalBooth: [],
          DailyRentalOtherCharges: [],
          AppliedDiscountModel: []
        }

        if (this.paymentData.DailyRentalBooth.length > 0)
          obj.DailyRentalBooth = this.paymentData.DailyRentalBooth;

        if (this.paymentData.DailyRentalOtherCharges.length > 0)
          obj.DailyRentalOtherCharges = this.paymentData.DailyRentalOtherCharges;

        if (this.paymentData.AppliedDiscountModel.length > 0)
          obj.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const sub = this.operationRentalService.SaveDailyRental(obj).subscribe(async (res: any) => {
          if (res.Success) {
            this.accountReceipId = res.Id;
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            const settings = this.paymentForm.controls['paymentDetails'].value;

            if (this.paymentData.TempRecordNo != 0) {
              const temp = {
                TempRecordNo: this.paymentData.TempRecordNo.toString(),
                BoothNumber: ""
              }
              this.subscriptions.push(
                this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
            this.sharedDataService.paymentSharedData = '';
            this.openConfirmationPage = true;
          } else {
            this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            if (this.paymentData.TempRecordNo != 0) {
              const temp = {
                TempRecordNo: this.paymentData.TempRecordNo.toString(),
                BoothNumber: ""
              }
              this.subscriptions.push(
                this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
          // const url = 'ops/vendors/details/' + this.vendorId + '/3';
          // this.router.navigate([url]);               

          this.loaderIcon = Promise.resolve(false);
        });

        this.subscriptions.push(sub);
        break;
      case 2:
        var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0 && this.paymentOnly) {
          this.paymentOnly = false;
        }
        var objMonthly = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          PriorBalance: this.paymentData.PriorBalance,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: 0,
          CheckAmount: 0,
          CheckNo: "",
          ChangeAmount: 0,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount: 0,
          CardType: "",
          PaymentDone: true,
          WebRentalRequest: true,
          PaymentDueDate: this.paymentData.PaymentDueDate,
          VerifoneId: response.VerifoneId,
          DiscountAmount: this.paymentData.DiscountAmount,
          ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
          BookingFee: this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')),
          MonthlyRentalBooth: [],
          MonthlyOtherRentalCharges: [],
          AppliedDiscountModel: []
        }

        if (this.paymentData.MonthlyRentalBooth.length > 0)
          objMonthly.MonthlyRentalBooth = this.paymentData.MonthlyRentalBooth;


        if (this.paymentData.MonthlyOtherRentalCharges.length > 0)
          objMonthly.MonthlyOtherRentalCharges = this.paymentData.MonthlyOtherRentalCharges;

        if (this.paymentData.AppliedDiscountModel.length > 0)
          objMonthly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const subMonthly = this.operationRentalService.saveMonthlyRental(this.marketId, objMonthly).subscribe((res: any) => {
          if (res.Success) {
            this.accountReceipId = res.Id;
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            this.sharedDataService.paymentSharedData = '';
            this.openConfirmationPage = true;
          }
          else {
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
          const url = 'ops/vendors/details/' + this.vendorId + '/3';
          this.loaderIcon = Promise.resolve(false);
        });
        this.subscriptions.push(subMonthly);
        break;

      case 4:
        var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0 && this.paymentOnly) {
          this.paymentOnly = false;
        }
        var objCombinedMonthly = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          PriorBalance: this.paymentData.PriorBalance,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: 0,
          CheckAmount: 0,
          CheckNo: "",
          ChangeAmount: 0,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount: 0,
          CardType: "",
          PaymentDone: true,
          WebRentalRequest: true,
          PaymentDueDate: this.paymentData.PaymentDueDate,
          VerifoneId: response.VerifoneId,
          DiscountAmount: this.paymentData.DiscountAmount,
          ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
          BookingFee: this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')),
          MonthlyRentalBooth: [],
          MonthlyOtherRentalCharges: [],
          AppliedDiscountModel: []
        }

        if (this.paymentData.MonthlyRentalBooth.length > 0)
          objCombinedMonthly.MonthlyRentalBooth = this.paymentData.MonthlyRentalBooth;


        if (this.paymentData.MonthlyOtherRentalCharges.length > 0)
          objCombinedMonthly.MonthlyOtherRentalCharges = this.paymentData.MonthlyOtherRentalCharges;

        if (this.paymentData.AppliedDiscountModel.length > 0)
          objCombinedMonthly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const subCM = this.operationRentalService.SaveCombinedMonthlyRentalTransactions(objCombinedMonthly).subscribe((res: any) => {
          if (res.Success) {
            this.accountReceipId = res.Id;
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            this.sharedDataService.paymentSharedData = '';
            this.openConfirmationPage = true;
          } else {
            this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            if (this.paymentData.TempRecordNo != 0) {
              const temp = {
                TempRecordNo: this.paymentData.TempRecordNo.toString(),
                BoothNumber: ""
              }
              this.subscriptions.push(
                this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
          // const url = 'ops/vendors/details/' + this.vendorId + '/3';
          // this.router.navigate([url]);               

          this.loaderIcon = Promise.resolve(false);
        });

        this.subscriptions.push(subCM);
        break;

      case 5:
        var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0 && this.paymentOnly) {
          this.paymentOnly = false;
        }
        var objCombinedWeekly = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          PriorBalance: this.paymentData.PriorBalance,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: 0,
          CheckAmount: 0,
          CheckNo: "",
          ChangeAmount: 0,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount: 0,
          CardType: "",
          PaymentDone: true,
          WebRentalRequest: true,
          PaymentDueDate: this.paymentData.PaymentDueDate,
          VerifoneId: response.VerifoneId,
          DiscountAmount: this.paymentData.DiscountAmount,
          ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
          BookingFee: this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')),
          CombinedWeeklyRentalBooth: [],
          WeeklyRentalOtherCharges: [],
          AppliedDiscountModel: []
        }

        if (this.paymentData.CombinedWeeklyRentalBooth.length > 0)
          objCombinedWeekly.CombinedWeeklyRentalBooth = this.paymentData.CombinedWeeklyRentalBooth;


        if (this.paymentData.WeeklyRentalOtherCharges.length > 0)
          objCombinedWeekly.WeeklyRentalOtherCharges = this.paymentData.WeeklyRentalOtherCharges;

        if (this.paymentData.AppliedDiscountModel.length > 0)
          objCombinedWeekly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const subCW = this.operationRentalService.SaveCombinedWeeklyRentalTransactions(objCombinedWeekly).subscribe((res: any) => {
          if (res.Success) {
            this.accountReceipId = res.Id;
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            this.sharedDataService.paymentSharedData = '';
            this.openConfirmationPage = true;
          } else {
            this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            if (this.paymentData.TempRecordNo != 0) {
              const temp = {
                TempRecordNo: this.paymentData.TempRecordNo.toString(),
                BoothNumber: ""
              }
              this.subscriptions.push(
                this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
          // const url = 'ops/vendors/details/' + this.vendorId + '/3';
          // this.router.navigate([url]);               

          this.loaderIcon = Promise.resolve(false);
        });

        this.subscriptions.push(subCW);
        break;

      case 3:
        var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0 && this.paymentOnly) {
          this.paymentOnly = false;
        }
        var objWeekly = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          PriorBalance: this.paymentData.PriorBalance,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: 0,
          CheckAmount: 0,
          CheckNo: "",
          ChangeAmount: 0,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount: 0,
          CardType: "",
          PaymentDone: true,
          WebRentalRequest: true,
          PaymentDueDate: this.paymentData.PaymentDueDate,
          VerifoneId: response.VerifoneId,
          DiscountAmount: this.paymentData.DiscountAmount,
          ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
          BookingFee: this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('additionalCharges').value.toString().replace(/[^0-9.-]/g, '')),
          WeeklyRentalBooth: [],
          WeeklyRentalOtherCharges: [],
          AppliedDiscountModel: []
        }

        if (this.paymentData.WeeklyRentalBooth.length > 0)
          objWeekly.WeeklyRentalBooth = this.paymentData.WeeklyRentalBooth;


        if (this.paymentData.WeeklyRentalOtherCharges.length > 0)
          objWeekly.WeeklyRentalOtherCharges = this.paymentData.WeeklyRentalOtherCharges;

        if (this.paymentData.AppliedDiscountModel.length > 0)
          objWeekly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const subW = this.operationRentalService.SaveWeeklyRentalTransactions(objWeekly).subscribe((res: any) => {
          if (res.Success) {
            this.accountReceipId = res.Id;
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            this.sharedDataService.paymentSharedData = '';
            this.openConfirmationPage = true;
          } else {
            this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            if (this.paymentData.TempRecordNo != 0) {
              const temp = {
                TempRecordNo: this.paymentData.TempRecordNo.toString(),
                BoothNumber: ""
              }
              this.subscriptions.push(
                this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                })
              );
            }
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
          // const url = 'ops/vendors/details/' + this.vendorId + '/3';
          // this.router.navigate([url]);               

          this.loaderIcon = Promise.resolve(false);
        });

        this.subscriptions.push(subW);
        break;
    }
  }

  voidPaymentResponse() {
    this.voidPayment = false;
      const url =  'home/' + this.marketId
      this.router.navigate([url]);
  }

  getCCCredentials() {
    this.paymentService.getCCCredentials(this.marketId).subscribe((res) => {
      if (res !== null) {
        this.paymentGatewayCredentials = res.GatewayDetails ? JSON.parse(res.GatewayDetails) : null;
        this.processCreditCardOnline = res.ProcessCreditCardOnline;
      }
    })
  }

  ClearPay() {
    this.paymentForm.controls['paymentDetails'].patchValue({
      cashAmount: this.customCurrency.transform("0.00"),
      checkAmount: this.customCurrency.transform("0.00"),
      creditCardAmount: this.customCurrency.transform("0.00"),
      directDebitAmount: this.customCurrency.transform("0.00"),
      convenienceFee: this.customCurrency.transform("0.00"),
      changeAmount: this.customCurrency.transform("0.00"),
      checkNo: '',
      creditCardTypes:'1',
      totalDue:  this.customCurrency.transform(this.actualTotalDue.toString()),
      balanceAmount:this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString())
    });
  }

  cancel(){
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want cancel ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then((result) => {
      if (result.value) {
        if (this.newVendor) {
          this.cancelClick.emit();
        }
        else {
          const url = 'rent-online/' + this.marketId + '/' + this.vendorId;
          this.router.navigate([url]);      //this.location.();
        }
      }
    });
  }
  
  ClosePaymentPage(){
    this.processPayment = false;
    this.voidPayment = false;
    this.disablePayBtnAfterSubmit = false;
  }

  ngOnDestroy(): void {
    //this.sharedDataService.paymentSharedData = {};
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
